import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../plugins/axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
 
 
  {
    path: '/',
    name: 'loginPanel',
    meta:{
      prefix:'panel'
    },
    component: () => import('../views/Panel/Auth/Login.vue')
  },

  {
    path: '/panel',
    meta:{
      autenticado:true,
      prefix:'panel'
    },
    component: () => import('../views/Panel/Plantilla.vue'),
    redirect:{name:'inicioPanel'},
    children:[
      {
        path: 'inicio',
        name: 'inicioPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Inicio/Index.vue')
      },

      //rutas para mantenimiento perfil de usuario
      {
        path: 'perfil-usuario',
        name: 'PerfilUsuarioPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/PerfilUsuario/Index.vue')
      },
      //fin rutas para mantenimiento perfil de usuario

      //rutas para mantenimiento roles
      {
        path: 'roles',
        name: 'rolesPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Roles/Index.vue')
      },
      {
        path: 'roles/crear',
        name: 'rolesCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Roles/Crear.vue')
      },
      {
        path: 'roles/editar/:id',
        name: 'rolesEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Roles/Editar.vue')
      },

      //fin rutas para mantenimiento roles

      //rutas para mantenimiento usuarios
      {
        path: 'usuarios',
        name: 'usuariosPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Usuarios/Index.vue')
      },
      {
        path: 'usuarios/crear',
        name: 'usuariosCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Usuarios/Crear.vue')
      },
      {
        path: 'usuarios/editar/:idusuario',
        name: 'usuariosEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Usuarios/Editar.vue')
      },
      {
        path: 'usuarios/visualizar/:idusuario',
        name: 'usuariosVisualizarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Usuarios/Visualizar.vue')
      },
      //fin rutas para mantenimiento usuarios

      //rutas para mantenimiento empresa
      {
        path: 'empresa',
        name: 'empresaPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Empresa/Index.vue')
      },
      //fin rutas para mantenimiento empresa

      //rutas para mantenimiento seo
      {
        path: 'seo',
        name: 'seoPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Seo/Index.vue')
      },
      //fin rutas para mantenimiento seo

      //rutas para mantenimiento paginas
      {
        path: 'paginas',
        name: 'paginasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Paginas/Index.vue')
      },
      {
        path: 'paginas/crear',
        name: 'paginasCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Paginas/Crear.vue')
      },
      {
        path: 'paginas/editar/:idpagina',
        name: 'paginasEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Paginas/Editar.vue')
      },
      {
        path: 'paginas/visualizar/:idpagina',
        name: 'paginasVisualizarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Paginas/Visualizar.vue')
      },
      //fin rutas para mantenimiento paginas

      //rutas para mantenimiento blogs
      {
        path: 'blogs',
        name: 'blogsPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Blogs/Index.vue')
      },
      {
        path: 'blogs/crear',
        name: 'blogsCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Blogs/Crear.vue')
      },
      {
        path: 'blogs/editar/:idblog',
        name: 'blogsEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Blogs/Editar.vue')
      },
      //fin rutas para mantenimiento blogs

      //rutas para mantenimiento popupManager
      {
        path: 'popup-manager',
        name: 'popupManagerPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/PopupManager/Index.vue')
      },
      {
        path: 'popup-manager/crear',
        name: 'popupManagerCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/PopupManager/Crear.vue')
      },
      {
        path: 'popup-manager/editar/:idpopup_manager',
        name: 'popupManagerEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/PopupManager/Editar.vue')
      },
      //fin rutas para mantenimiento popupManager

       //rutas para mantenimiento lunas colores
      {
        path: 'lunas-colores',
        name: 'lunasColoresPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/LunaColor/Index.vue')
      },
      {
        path: 'lunas-colores/crear',
        name: 'lunasColoresCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/LunaColor/Crear.vue')
      },
      {
        path: 'lunas-colores/editar/:idluna_color',
        name: 'lunasColoresEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/LunaColor/Editar.vue')
      },
      //fin rutas para mantenimiento lunas colores

      //rutas para mantenimiento productos colores
      {
        path: 'productos-colores',
        name: 'productosColoresPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/ProductoColor/Index.vue')
      },
      {
        path: 'productos-colores/crear',
        name: 'productosColoresCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/ProductoColor/Crear.vue')
      },
      {
        path: 'productos-colores/editar/:idproducto_color',
        name: 'productosColoresEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/ProductoColor/Editar.vue')
      },
      //fin rutas para mantenimiento productos colores


      //rutas para mantenimiento lunas
      {
        path: 'lunas',
        name: 'lunasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Lunas/Index.vue')
      },
      {
        path: 'lunas/crear',
        name: 'lunasCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Lunas/Crear.vue')
      },
      {
        path: 'lunas/editar/:idluna',
        name: 'lunasEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Lunas/Editar.vue')
      },
      //fin rutas para mantenimiento lunas


       //rutas para mantenimiento suscripciones
       {
        path: 'suscripciones',
        name: 'suscripcionesPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Suscripciones/Index.vue')
      },
      //fin rutas para mantenimiento suscripciones


      //rutas para mantenimiento clases
      {
        path: 'clases',
        name: 'clasesPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Clases/Index.vue')
      },
      {
        path: 'clases/crear',
        name: 'clasesCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Clases/Crear.vue')
      },
      {
        path: 'clases/editar/:idmodelo_producto',
        name: 'clasesEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Clases/Editar.vue')
      },
      //fin rutas para mantenimiento clases


      //rutas para mantenimiento categorias
      {
        path: 'categorias',
        name: 'categoriasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Categorias/Index.vue')
      },
      {
        path: 'categorias/crear',
        name: 'categoriasCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Categorias/Crear.vue')
      },
      {
        path: 'categorias/editar/:idcategoria',
        name: 'categoriasEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Categorias/Editar.vue')
      },
      //fin rutas para mantenimiento categorias


      //rutas para mantenimiento productos
      {
        path: 'productos',
        name: 'productosPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Productos/Index.vue')
      },
      {
        path: 'productos/crear',
        name: 'productosCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Productos/Crear.vue')
      },
      {
        path: 'productos/editar/:idproducto',
        name: 'productosEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Productos/Editar.vue')
      },
      //fin rutas para mantenimiento productos


      //rutas para mantenimiento pedido descuento
      {
        path: 'pedido-descuento',
        name: 'pedidoDescuentoPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/PedidoDescuento/Index.vue')
      },

      //rutas para mantenimiento cupones
      {
        path: 'cupones',
        name: 'cuponesPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Cupones/Index.vue')
      },
      {
        path: 'cupones/crear',
        name: 'cuponesCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Cupones/Crear.vue')
      },
      {
        path: 'cupones/editar/:idcupon',
        name: 'cuponesEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Cupones/Editar.vue')
      },
      //fin rutas para mantenimiento cupones


      //rutas para mantenimiento pedidos personalizados
      {
        path: 'pedidos-personalizados',
        name: 'pedidoPersonalizadoPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/PedidoPersonalizado/Index.vue')
      },
      {
        path: 'pedidos-personalizados/crear',
        name: 'pedidoPersonalizadoCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/PedidoPersonalizado/Crear.vue')
      },
      //fin rutas para mantenimiento personalizados


      //rutas para mantenimiento clientes
      {
        path: 'clientes',
        name: 'clientesPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Clientes/Index.vue')
      },
      {
        path: 'clientes/crear',
        name: 'clientesCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Clientes/Crear.vue')
      },
      {
        path: 'clientes/editar/:idcliente',
        name: 'clientesEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Clientes/Editar.vue')
      },
      //fin rutas para mantenimiento clientes

       //rutas para mantenimiento ventas
      {
        path: 'ventas',
        name: 'ventasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Ventas/Index.vue')
      },

      {
        path: 'ventasFinalizadas',
        name: 'ventasFinalizadasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Ventas/Finalizadas.vue')
      },


      {
        path: 'ventasCanceladas',
        name: 'ventasCanceladasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Ventas/Canceladas.vue')
      },

      //fin rutas para mantenimiento ventas

      //rutas para mantenimiento ventas old 2022
      {
        path: 'ventas-2022',
        name: 'ventasPanel-2022',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/VentasOld2022/Index.vue')
      },

      {
        path: 'ventasFinalizadas-2022',
        name: 'ventasFinalizadasPanel-2022',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/VentasOld2022/Finalizadas.vue')
      },


      {
        path: 'ventasCanceladas-2022',
        name: 'ventasCanceladasPanel-2022',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/VentasOld2022/Canceladas.vue')
      },

      //fin rutas para mantenimiento ventas old 202

      //rutas para mantenimiento reservas
      {
        path: 'reservas',
        name: 'reservasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Reservas/Index.vue')
      },

      //fin rutas para mantenimiento reservas



      //rutas para mantenimiento probador virtual
      {
        path: 'probadorVirtual',
        name: 'probadorVirtualPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/probadorVirtual/Index.vue')
      },
      //fin rutas para mantenimiento probador virtual


      //rutas para mantenimiento opticas
      {
        path: 'opticas',
        name: 'opticasPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Opticas/Index.vue')
      },
      {
        path: 'opticas/crear',
        name: 'opticasCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Opticas/Crear.vue')
      },
      {
        path: 'opticas/editar/:idoptica',
        name: 'opticasEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Opticas/Editar.vue')
      },
      //fin rutas para mantenimiento opticas


      //rutas para mantenimiento lista negra
      {
        path: 'listaNegra',
        name: 'listaNegraPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/ListaNegra/Index.vue')
      },
       //fin rutas para mantenimiento lista negra


      //rutas para mantenimiento tags
      {
        path: 'etiqueta',
        name: 'tagPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Tags/Index.vue')
      },
      {
        path: 'etiqueta/crear',
        name: 'tagCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Tags/Crear.vue')
      },
      {
        path: 'etiqueta/editar/:idtag',
        name: 'tagEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Tags/Editar.vue')
      },
      //fin rutas para mantenimiento tags

      //rutas para mantenimiento formas
      {
        path: 'forma',
        name: 'formaPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Formas/Index.vue')
      },
      {
        path: 'forma/crear',
        name: 'formaCrearPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Formas/Crear.vue')
      },
      {
        path: 'forma/editar/:idforma',
        name: 'formaEditarPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Formas/Editar.vue')
      },
      //fin rutas para mantenimiento formas

    ]
  }

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



//protegiendo rutas
router.beforeEach((to, from, next) => {

  NProgress.start();
  
  let token = Vue.prototype.$session.get('token');

  if (to.meta.prefix === 'panel') {

    if (to.name !== 'loginPanel') {

      axios.post('api/panel/login/verificarToken')
      .then((res) => {

        Vue.prototype.$session.set('usuario',res.data.usuario);

        next();
      }).catch((err) => {

        
      })
      
    }else{
 
        if (to.name === 'loginPanel' && token !== null &&  token !== undefined && token !== '') {
          next({name:'inicioPanel'});
        }else{
          next();
        }
      
    }
    
    
  }else{
    next();
  }

})



router.afterEach(() => {  
  NProgress.done();
}) 

//protegiendo rutas


export default router
